import EmblaCarousel from "embla-carousel";
import AutoHeight from "embla-carousel-auto-height";
import Autoplay from "embla-carousel-autoplay";
import ClassNames from "embla-carousel-class-names";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Gallery slider");
	const gallerySliders = document.querySelectorAll(".gallery-slider.embla");

	if (gallerySliders.length) {
		gallerySliders.forEach((slider) => {
			gallerySlider(slider);
		});
	}
}

function gallerySlider(slider) {
	const wrap = slider;
	const viewPort = wrap.querySelector(".gallery-slider .embla__viewport");
	const prevBtn = wrap.querySelector(".gallery-slider .embla__button--prev");
	const nextBtn = wrap.querySelector(".gallery-slider .embla__button--next");
	const dots = wrap.querySelector(".gallery-slider .embla__dot_navigation");

	// Add disabled class if slider has 1 slide
	const slides = wrap.querySelectorAll(".gallery-slider .slide");
	let status = true;
	if (slides.length === 1) {
		wrap.classList.add("disabled");
		status = false;
	}

	const embla = EmblaCarousel(
		viewPort,
		{
			active: status,
			// align: "start",
			align: "center",
			dragFree: true,
			duration: 20,
			loop: true
		},
		[ClassNames(), AutoHeight()]
	);

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Autoplay
	// const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
